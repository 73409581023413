import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import qs from 'query-string'
import { push } from 'connected-react-router'
import LoadingAnim from 'components/LoadingAnim'
import { BlockShimmer } from 'components/ShimmerLoader'
import {
  selectControlGroups,
  selectPolicies,
  selectLoadedControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import Icon from 'components/Icon'
import NodeViewTabs from 'components/NodeView/NodeViewTabs'
import Button from 'components/Button'
import MarkdownDisplay from 'components/MarkdownDisplay'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { hasFinishedEvaluatingFrameworks } from 'utils/sonraiUtils'
import BorderedCard from 'components/BorderedCard'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import TextLink from 'components/TextLink'
import SectionHeader from 'components/SectionHeader'
import {
  selectObjectives,
  selectObjectivesError,
  selectObjectivesLoading,
  selectEnablingObjectives,
  selectSwimlanesLoading,
} from 'containers/SonraiData/selectors'
import { enableObjective } from 'containers/SonraiData/actions'

import reducer from './reducer'
import {
  selectSwimlane,
  selectPolicyTickets,
  selectPolicyTicketsLoading,
  selectPolicyRollups,
  selectPolicyRollupsLoading,
  selectSwimlaneSrn,
  selectSwimlaneStats,
  selectSwimlaneRisk,
  selectSwimlaneRiskLoading,
} from './selectors'
import {
  fetchSwimlaneStats,
  fetchPolicyTickets,
  fetchPolicyRollups,
  fetchSwimlaneRisk,
} from './actions'
import sagas from './sagas'
import ObjectiveFramework from './ObjectiveFramework'
import RiskScore from 'containers/SwimlaneCheckup/RiskScore'
import ContentStat from 'containers/SwimlaneCheckup/ContentStat'

export class SwimlaneCheckupDetails extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto 1fr',
        gridTemplateAreas: '"breadcrumbs" "title" "policies"',
        gridColumnGap: '1em',
        gridRowGap: '0.5em',
        padding: '1em',
        height: '100%',
        overflow: 'auto',
      },
      body: {
        overflow: 'auto',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"stats" "objectives"',
      },
      stats: {
        height: '150px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      },
      header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateAreas: '"details button"',
      },
      headerButton: {
        gridArea: 'button',
      },
      tabContent: {
        background: 'white',
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        padding: '1em 2em',
        minHeight: '100%',
      },
      swimlaneStats: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto auto 1fr',
      },
    }

    props.fetchPolicyRollups({ swimlaneSrn: props.swimlaneSrn })
    props.fetchSwimlaneStats(props.swimlaneSrn)
    props.fetchSwimlaneRisk(props.swimlaneSrn)
  }

  enableObjective = ({ objectiveSrn }) => {
    this.props.enableObjective({
      objectiveSrn,
      swimlaneSrn: this.props.swimlane.get('srn'),
    })
  }

  loadSampleTickets = policySrns => {
    policySrns.forEach(srn => {
      this.props.fetchPolicyTickets({
        policySrn: srn,
        swimlaneSrn: this.props.swimlane.get('srn'),
      })
    })
  }

  renderEnableObjective = objective => {
    return (
      <div style={{ marginTop: '100px', textAlign: 'center' }}>
        <Button
          color="primary"
          onClick={() =>
            this.enableObjective({ objectiveSrn: objective.get('srn') })
          }
        >
          Enable {objective.get('title')} Objective
        </Button>
      </div>
    )
  }

  renderEnabledObjective = objective => {
    const hasEvaluatedFrameworks = hasFinishedEvaluatingFrameworks(
      objective.getIn(['appliedControlFrameworks', 'items'], List()),
      this.props.controlFrameworks,
      this.props.swimlaneSrn
    )
    if (!hasEvaluatedFrameworks) {
      return (
        <div
          style={{
            height: 'calc(15vh + 16px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: this.props.theme.neutralMedium,
          }}
        >
          <Icon
            fa
            name="digging"
            style={{
              color: this.props.theme.neutralMedium,
              fontSize: '3rem',
            }}
          />
          <span style={{ margin: '0.5rem' }}>Evaluating Frameworks...</span>
        </div>
      )
    }
    return objective
      .get('definedControlFrameworks', List())
      .sortBy(ele => ele.get('controlFrameworkOrder'))
      .map(cf => {
        if (this.props.policyRollupsLoading) {
          return (
            <div style={{ marginBottom: '1em', height: '60px' }}>
              <BlockShimmer />
            </div>
          )
        }

        return (
          <ObjectiveFramework
            key={cf.get('controlFrameworkSrn')}
            policies={this.props.policies}
            push={this.props.push}
            rollups={this.props.policyRollups}
            controlFramework={this.props.controlFrameworks.get(
              cf.get('controlFrameworkSrn'),
              Map()
            )}
            loadSampleTickets={this.loadSampleTickets}
            swimlaneSrn={this.props.swimlaneSrn}
            policyTickets={this.props.policyTickets}
            policyTicketsLoading={this.props.policyTicketsLoading}
            objectives={this.props.objectives}
          />
        )
      })
  }

  renderObjectiveTabContent = objective => {
    const appliedForSwimlane = objective
      .getIn(['appliedControlFrameworks', 'items'], List())
      .filter(
        config =>
          config &&
          config.get('srn') &&
          (!config.get('swimlaneSRNs') || //null swimlaneSRNs means its applied globally?
            config.get('swimlaneSRNs').includes(this.props.swimlane.get('srn')))
      )

    return (
      <div key={objective.get('srn')} label={objective.get('name')}>
        <div style={this.styles.tabContent}>
          <SectionHeader>{objective.get('name')}</SectionHeader>
          <MarkdownDisplay content={objective.get('description')} />

          {appliedForSwimlane.isEmpty()
            ? this.renderEnableObjective(objective)
            : this.renderEnabledObjective(objective)}
        </div>
      </div>
    )
  }

  renderObjectivesTabs = () => {
    if (this.props.objectivesLoading) {
      return <BlockShimmer />
    }

    const objectivesWithCFs = this.props.objectives.filter(
      obj => !obj.get('definedControlFrameworks', List()).isEmpty()
    )

    return (
      <NodeViewTabs
        style={{
          minHeight: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
        tabStyle={{ height: '100%' }}
      >
        {objectivesWithCFs.map(this.renderObjectiveTabContent)}
      </NodeViewTabs>
    )
  }

  renderStat = nodeType => {
    return (
      <ContentStat
        nodeType={nodeType}
        count={this.props.swimlaneStats.get(nodeType)}
      />
    )
  }

  render() {
    if (this.props.swimlanesLoading || !this.props.loadedControlGroups) {
      return <LoadingAnim />
    }

    return (
      <div style={this.styles.container}>
        <div style={this.styles.breadcrumbs}>
          <Breadcrumb>
            <BreadcrumbItem>
              <TextLink
                color="primary"
                to={{ pathname: '/App/SwimlaneCheckup' }}
              >
                All Swimlanes
              </TextLink>
            </BreadcrumbItem>
            <BreadcrumbItem>Swimlane Checkup</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <BorderedCard style={this.styles.header}>
          <div>
            <SectionHeader style={this.styles.title}>
              {this.props.swimlane.get('title')}
            </SectionHeader>
            <MarkdownDisplay
              content={this.props.swimlane.get('description')}
            ></MarkdownDisplay>
          </div>
          <div style={this.styles.headerButton}>
            <TextLink
              color="primary"
              to={{
                pathname: '/App/SwimlaneDetails',
                search: qs.stringify({ srn: this.props.swimlane.get('srn') }),
              }}
              style={{ marginRight: '1em' }}
            >
              Edit Swimlane
            </TextLink>
            <Button
              color="primary"
              outline
              to={{
                pathname: '/App/SecurityCenter',
                search: qs.stringify({
                  status: 'OPEN',
                  view: 'resources',
                  swimlane: this.props.swimlane.get('title'),
                }),
              }}
            >
              View in Security Center
            </Button>
          </div>
        </BorderedCard>
        <BorderedCard style={this.styles.body}>
          <div style={this.styles.stats}>
            <div style={{ textAlign: 'center' }}>
              <RiskScore
                swimlane={this.props.swimlane}
                value={this.props.swimlaneRisk || Map()}
                loading={this.props.swimlaneRiskLoading}
              />
            </div>
            <div style={this.styles.swimlaneStats}>
              {this.renderStat('Account')}
              {this.renderStat('Identity')}
              {this.renderStat('Protection')}
              {this.renderStat('Infrastructure')}
              {this.renderStat('Data')}
            </div>
          </div>

          {this.renderObjectivesTabs()}
        </BorderedCard>
      </div>
    )
  }
}

SwimlaneCheckupDetails.propTypes = {
  controlFrameworks: ImmutablePropTypes.map.isRequired,
  fetchPolicyRollups: PropTypes.func.isRequired,
  fetchPolicyTickets: PropTypes.func.isRequired,
  fetchSwimlaneRisk: PropTypes.func.isRequired,
  fetchSwimlaneStats: PropTypes.func.isRequired,
  enableObjective: PropTypes.func.isRequired,
  loadedControlGroups: PropTypes.bool,
  objectives: ImmutablePropTypes.iterable.isRequired,
  objectivesLoading: PropTypes.bool,
  policies: ImmutablePropTypes.map.isRequired,
  policyRollups: ImmutablePropTypes.map.isRequired,
  policyTickets: ImmutablePropTypes.map.isRequired,
  policyTicketsLoading: ImmutablePropTypes.map.isRequired,
  policyRollupsLoading: PropTypes.bool,
  push: PropTypes.func.isRequired,
  swimlane: ImmutablePropTypes.map.isRequired,
  swimlanesLoading: PropTypes.bool,
  swimlaneRisk: PropTypes.string,
  swimlaneRiskLoading: PropTypes.bool,
  swimlaneStats: ImmutablePropTypes.map.isRequired,
  swimlaneSrn: PropTypes.string,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  controlFrameworks: selectControlGroups,
  enablingObjective: selectEnablingObjectives,
  loadedControlGroups: selectLoadedControlGroups,
  objectives: selectObjectives,
  objectivesError: selectObjectivesError,
  objectivesLoading: selectObjectivesLoading,
  policies: selectPolicies,
  policyRollups: selectPolicyRollups,
  policyRollupsLoading: selectPolicyRollupsLoading,
  policyTickets: selectPolicyTickets,
  policyTicketsLoading: selectPolicyTicketsLoading,
  swimlane: selectSwimlane,
  swimlaneRisk: selectSwimlaneRisk,
  swimlaneRiskLoading: selectSwimlaneRiskLoading,
  swimlaneSrn: selectSwimlaneSrn,
  swimlaneStats: selectSwimlaneStats,
  swimlanesLoading: selectSwimlanesLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSwimlaneStats,
      enableObjective,
      fetchPolicyRollups,
      fetchPolicyTickets,
      fetchSwimlaneRisk,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'swimlaneCheckupDetails', reducer })
const withSaga = injectSaga({ key: 'swimlaneCheckupDetails', saga: sagas })

export default compose(
  withReducer,
  withConnect,
  withSaga,
  themeable
)(SwimlaneCheckupDetails)
