import {
  FETCH_SWIMLANE_STATS,
  SET_SWIMLANE_STATS,
  FETCH_SWIMLANE_STATS_ERROR,
  FETCH_POLICY_TICKETS,
  FETCH_POLICY_TICKETS_ERROR,
  SET_POLICY_TICKETS,
  FETCH_POLICY_ROLLUPS,
  FETCH_POLICY_ROLLUPS_ERROR,
  SET_POLICY_ROLLUPS,
  FETCH_SWIMLANE_RISK,
  SET_SWIMLANE_RISK,
  FETCH_SWIMLANE_RISK_ERROR,
} from './constants'

import { createAction } from 'redux-actions'

export const fetchSwimlaneStats = createAction(FETCH_SWIMLANE_STATS)
export const setSwimlaneStats = createAction(SET_SWIMLANE_STATS)
export const fetchSwimlaneStatsError = createAction(FETCH_SWIMLANE_STATS_ERROR)

export const fetchPolicyTickets = createAction(FETCH_POLICY_TICKETS)
export const fetchPolicyTicketsError = createAction(FETCH_POLICY_TICKETS_ERROR)
export const setPolicyTickets = createAction(SET_POLICY_TICKETS)

export const fetchPolicyRollups = createAction(FETCH_POLICY_ROLLUPS)
export const fetchPolicyRollupsError = createAction(FETCH_POLICY_ROLLUPS_ERROR)
export const setPolicyRollups = createAction(SET_POLICY_ROLLUPS)

export const fetchSwimlaneRisk = createAction(FETCH_SWIMLANE_RISK)
export const setSwimlaneRisk = createAction(SET_SWIMLANE_RISK)
export const fetchSwimlaneRiskError = createAction(FETCH_SWIMLANE_RISK_ERROR)
