import {
  all,
  put,
  takeLatest,
  call,
  select,
  takeEvery,
} from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { List, Set } from 'immutable'
import { getClient } from 'apolloClient'

import { TICKET_STATUS } from 'appConstants'
import { fetchObjectives } from 'containers/SonraiData/sagas'
import {
  selectQueryTypes,
  selectObjectives,
} from 'containers/SonraiData/selectors'
import { selectControlGroups } from 'containers/ControlFrameworkData/selectors'
import {
  getControlGroups,
  getPolicies,
} from 'containers/ControlFrameworkData/sagas'
import { getInterfaceCount } from 'utils/sonraiUtils'

import {
  setSwimlaneStats,
  fetchSwimlaneStatsError,
  fetchPolicyTicketsError,
  fetchPolicyRollupsError,
  setPolicyRollups,
  setPolicyTickets,
  setSwimlaneRisk,
  fetchSwimlaneRiskError,
} from './actions'
import {
  FETCH_SWIMLANE_STATS,
  FETCH_POLICY_TICKETS,
  FETCH_POLICY_ROLLUPS,
  FETCH_SWIMLANE_RISK,
} from './constants'

function* fetchSwimlaneStats({ payload }) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getStatsBySwimlane($swimlaneSrn: String) {
          GroupedQuery(where: { swimlanes: [$swimlaneSrn], keys: [Label] }) {
            key
            items {
              value
              count
              hasGroupedResult {
                items {
                  value
                  count
                }
              }
            }
          }
        }
      `,
      variables: {
        swimlaneSrn: payload,
      },
    })

    if (results.errors) {
      throw new Error('Error fetching stats for swimlanes')
    }
    const queryTypes = yield select(selectQueryTypes)

    const countsByType = {}
    const stats = results.data.GroupedQuery.items
    stats.forEach(swimlaneStat => {
      countsByType[swimlaneStat.value] = swimlaneStat.count
    })

    //calculate counts for the interface types
    countsByType['Identity'] = getInterfaceCount(
      queryTypes,
      'Identity',
      countsByType
    )
    countsByType['Data'] = getInterfaceCount(queryTypes, 'Data', countsByType)
    countsByType['Protection'] = getInterfaceCount(
      queryTypes,
      'Protection',
      countsByType
    )
    countsByType['Infrastructure'] = getInterfaceCount(
      queryTypes,
      'Infrastructure',
      countsByType
    )

    yield put(setSwimlaneStats(countsByType))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching stats for swimlanes', e)
    yield put(fetchSwimlaneStatsError())
  }
}

function* fetchPolicyRollups({ payload }) {
  try {
    const swimlaneSrn = payload.swimlaneSrn

    let objectives = yield select(selectObjectives)
    if (objectives.isEmpty()) {
      yield call(fetchObjectives)
      objectives = yield select(selectObjectives)
    }

    //get a list of control frameworks that are part of objectives
    let cfSrnsForObjectives = Set()
    objectives.forEach(objective =>
      objective
        .getIn(['appliedControlFrameworks', 'items'], List())
        .forEach(config => {
          if (
            config &&
            config.get('srn') &&
            (!config.get('swimlaneSRNs') || //null swimlaneSRNs means its applied globally?
              config.get('swimlaneSRNs').includes(swimlaneSrn))
          ) {
            cfSrnsForObjectives = cfSrnsForObjectives.add(config.get('srn'))
          }
        })
    )

    //get a list of all the policies that are part of the objective CFs
    let controlFrameworks = yield select(selectControlGroups)
    if (controlFrameworks.isEmpty()) {
      yield call(getControlGroups)
      yield call(getPolicies)
      controlFrameworks = yield select(selectControlGroups)
    }

    let policyIds = Set()
    controlFrameworks.forEach(cf => {
      if (cfSrnsForObjectives.has(cf.get('srn'))) {
        cf.getIn(['contains', 'items'], List()).forEach(pol => {
          policyIds = policyIds.add(pol.get('srn'))
        })
      }
    })

    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getTicketsForObjectivesPolicies(
          $policySrns: [String!]!
          $swimlaneSrn: String!
        ) {
          TicketRollups(
            where: {
              resourceSRN: { value: "ALL" }
              resourceType: { value: "ALL" }
              resourceLabel: { value: "ALL" }
              actionClassification: { value: "ALL" }
              severityCategory: { value: "ALL" }
              ticketKey: { op: IN_LIST, values: $policySrns }
              ticketType: { value: "Policy" }
              orgName: { value: "ALL" }
              account: { value: "ALL" }
              status: { op: IN_LIST, values: ["${TICKET_STATUS.NEW}", "${TICKET_STATUS.IN_PROGRESS}"] }
              swimlaneSrns: { op: EQ, value: $swimlaneSrn }
            }
          ) {
            items {
              ticketKey
              count
              swimlaneSrns
            }
          }
        }
      `,
      variables: {
        policySrns: policyIds.toList().toJS(),
        swimlaneSrn: swimlaneSrn,
      },
    })

    const rollups = _.get(results, ['data', 'TicketRollups', 'items'])

    if (results.errors || !rollups) {
      throw new Error(results.errors)
    }

    const findingsByPolicy = {}
    rollups.forEach(rollup => {
      const policyId = rollup.ticketKey
      if (findingsByPolicy[policyId]) {
        findingsByPolicy[policyId] += rollup.count
      } else {
        findingsByPolicy[policyId] = rollup.count
      }
    })

    yield put(setPolicyRollups(findingsByPolicy))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching policy tickets for objectives', e)
    yield put(fetchPolicyRollupsError())
  }
}

function* fetchTicketsForPolicy({ payload }) {
  const policySrn = payload.policySrn
  const swimlaneSrn = payload.swimlaneSrn

  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getSampleTicketsForPolicy(
          $policySrn: String!
          $swimlaneSrn: String!
        ) {
          Tickets(
            where: {
              ticketKey: { op: EQ, value: $policySrn }
              status: { op: IN_LIST, values: ["${TICKET_STATUS.NEW}", "${TICKET_STATUS.IN_PROGRESS}"] }
              swimlaneSrns: { op: EQ, value: $swimlaneSrn }
            }
          ) {
            items(orderBy: [{ severityNumeric: { order: DESC } }], limit: 5) {
              title
              status
              assignedTo
              severityNumeric
              swimlaneSRNs
              createdDate
              lastModified
              resource {
                srn
                label
                name
                friendlyName
                account
                active
                createdDate
                cloudType
                country
                region
                resourceId
                importance
                swimlaneSRNs
                __typename
              }
              evidence {
                conditions
                path
                count
                fieldType
                boolValue
                intValue
                longValue
                value
                prevBoolValue
                prevIntValue
                prevLongValue
                prevValue
              }
              firstSeen
              lastSeen
              createdBy
              ticketKey
              ticketType
              resourceType
              resourceLabel
              actionClassification
              account
              srn
              resourceId
            }
          }
        }
      `,
      variables: {
        policySrn: policySrn,
        swimlaneSrn: swimlaneSrn,
      },
    })

    const tickets = _.get(results, ['data', 'Tickets', 'items'])

    if (results.errors || !tickets) {
      throw new Error(results.errors)
    }

    yield put(
      setPolicyTickets({
        policySrn: policySrn,
        tickets: tickets,
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching sample tickets for policy', e)
    yield put(fetchPolicyTicketsError({ policySrn: policySrn }))
  }
}

function* fetchSwimlaneRisk({ payload }) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getSwimlaneRisk($swimlaneSrn: String!) {
          TicketRollups(
            where: {
              resourceSRN: { value: "ALL" }
              resourceType: { value: "ALL" }
              resourceLabel: { value: "ALL" }
              actionClassification: { value: "ALL" }
              severityCategory: { value: "ALL" }
              ticketKey: { value: "ALL" }
              ticketType: { value: "ALL" }
              orgName: { value: "ALL" }
              account: { value: "ALL" }
              status: { op: IN_LIST, values: ["${TICKET_STATUS.NEW}", "${TICKET_STATUS.IN_PROGRESS}"] }
              swimlaneSrns: {op: EQ value: $swimlaneSrn}
            }
          ) {
            items {
              swimlaneSrns
              riskScore
              riskScoreNumeric
            }
          }
        }
      `,
      variables: {
        swimlaneSrn: payload,
      },
    })

    const rollups = _.get(results, ['data', 'TicketRollups', 'items'])

    if (results.errors || !rollups) {
      throw new Error('Error fetching risk scores for swimlanes')
    }

    const riskScore = _.get(rollups, [0, 'riskScore'], 'A')
    const riskScoreNumeric = _.get(rollups, [0, 'riskScoreNumeric'], 100)

    yield put(
      setSwimlaneRisk({
        riskScore,
        riskScoreNumeric,
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching the ticket status for swimlanes', e)
    yield put(fetchSwimlaneRiskError())
  }
}

function* swimlaneCheckupDetailsSaga() {
  yield all([
    takeLatest(FETCH_SWIMLANE_STATS, fetchSwimlaneStats),
    takeLatest(FETCH_POLICY_ROLLUPS, fetchPolicyRollups),
    takeEvery(FETCH_POLICY_TICKETS, fetchTicketsForPolicy),
    takeLatest(FETCH_SWIMLANE_RISK, fetchSwimlaneRisk),
  ])
}

export default swimlaneCheckupDetailsSaga
