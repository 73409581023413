import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import { themeShape } from 'containers/ThemeManager/Themeable'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import ProgressBar from 'components/ProgressBar'
import themeable from 'containers/ThemeManager/Themeable'
import MarkdownDisplay from 'components/MarkdownDisplay'

import PassingPolicy from './PassingPolicy'
import FailingPolicy from './FailingPolicy'

const ObjectiveFramework = ({
  controlFramework,
  policies,
  push,
  rollups,
  swimlaneSrn,
  theme,
  loadSampleTickets,
  policyTickets,
  policyTicketsLoading,
  objectives,
}) => {
  const [expanded, setExpanded] = useState(false)

  const fetchTickets = () => {
    const failingCfPolicySrns = controlFramework
      .getIn(['contains', 'items'], List())
      .map(pol => pol.get('srn'))
      .filter(srn => rollups.get(srn))
      .toJS()
    loadSampleTickets(failingCfPolicySrns)
  }

  const toggleExpand = () => {
    setExpanded(!expanded)

    if (!expanded) {
      //TODO: only load tickets if they are already not loaded
      fetchTickets()
    }
  }

  const onDoubleClickRow = ({ srn }) => {
    push({
      pathname: `/App/TicketDetails`,
      search: `srn=${srn}`,
    })
  }

  const cfPolicies = controlFramework
    .getIn(['contains', 'items'], List())
    .map(pol => policies.get(pol.get('srn')) || Map())
    .sortBy(policy => policy.get('alertingLevelNumeric'))
    .reverse()

  const total = cfPolicies.size
  const failingCount = cfPolicies.filter(pol => rollups.get(pol.get('srn')))
    .size
  const passPercent = Math.floor(((total - failingCount) / total) * 100)

  const icon =
    failingCount > 0 ? (
      <Icon
        fa
        name="exclamation-triangle-solid"
        color={theme.fail}
        style={{ marginRight: '0.5em' }}
      />
    ) : (
      <Icon
        fa
        name="check-circle-solid"
        color={theme.success}
        style={{ marginRight: '0.5em' }}
      />
    )
  return (
    <div style={{ margin: '1em 0' }}>
      <div style={{ marginBottom: '0.5em' }}>
        <BorderlessButton
          onClick={toggleExpand}
          style={{ color: theme.bodyText }}
        >
          <Icon
            fa
            name={expanded ? 'caret-down' : 'caret-right'}
            style={{ marginRight: '0.5em' }}
          />
          {icon}
          {controlFramework.get('title')}
        </BorderlessButton>
      </div>
      <ProgressBar value={passPercent} />
      {expanded && (
        <Fragment>
          <div style={{ marginBottom: '0.5em', marginTop: '0.3em' }}>
            <MarkdownDisplay content={controlFramework.get('description')} />
          </div>
          <div style={{ padding: '1em' }}>
            {cfPolicies.map(policy => {
              const ticketsCount = rollups.get(policy.get('srn')) || 0
              const tickets = policyTickets.get(policy.get('srn')) || List()
              const ticketsLoading = policyTicketsLoading.get(policy.get('srn'))

              if (ticketsCount > 0) {
                return (
                  <FailingPolicy
                    key={policy.get('srn')}
                    onDoubleClickRow={onDoubleClickRow}
                    policy={policy}
                    swimlaneSrn={swimlaneSrn}
                    ticketsCount={ticketsCount}
                    theme={theme}
                    tickets={tickets}
                    ticketsLoading={ticketsLoading}
                    refreshData={fetchTickets}
                    objectives={objectives}
                  />
                )
              } else {
                return (
                  <PassingPolicy
                    key={policy.get('srn')}
                    policy={policy}
                    theme={theme}
                  />
                )
              }
            })}
          </div>
        </Fragment>
      )}
    </div>
  )
}

ObjectiveFramework.propTypes = {
  controlFramework: ImmutablePropTypes.map.isRequired,
  swimlaneSrn: PropTypes.string,
  policies: ImmutablePropTypes.map.isRequired,
  push: PropTypes.func.isRequired,
  rollups: ImmutablePropTypes.map.isRequired,
  loadSampleTickets: PropTypes.func.isRequired,
  policyTickets: ImmutablePropTypes.map.isRequired,
  policyTicketsLoading: ImmutablePropTypes.map.isRequired,
  objectives: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
}
export default themeable(ObjectiveFramework)
