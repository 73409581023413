import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  FETCH_SWIMLANE_STATS,
  SET_SWIMLANE_STATS,
  FETCH_SWIMLANE_STATS_ERROR,
  FETCH_POLICY_TICKETS,
  FETCH_POLICY_TICKETS_ERROR,
  SET_POLICY_TICKETS,
  FETCH_POLICY_ROLLUPS,
  FETCH_POLICY_ROLLUPS_ERROR,
  SET_POLICY_ROLLUPS,
  FETCH_SWIMLANE_RISK,
  FETCH_SWIMLANE_RISK_ERROR,
  SET_SWIMLANE_RISK,
} from './constants'

const initialState = fromJS({
  swimlaneStats: {},
  swimlaneStatsLoading: false,
  policyTickets: {},
  policyTicketsLoading: {},
  policyRollups: {},
  policyRollupsLoading: false,
  swimlaneRisk: null,
  policyRiskLoading: false,
})

const swimlaneManagerReducer = handleActions(
  {
    [FETCH_SWIMLANE_STATS]: state => state.set('swimlaneStatsLoading', true),
    [FETCH_SWIMLANE_STATS_ERROR]: state =>
      state.set('swimlaneStatsLoading', false),
    [SET_SWIMLANE_STATS]: (state, { payload }) =>
      state
        .set('swimlaneStats', fromJS(payload))
        .set('swimlaneStatsLoading', false),
    [FETCH_POLICY_TICKETS]: (state, { payload }) =>
      state.setIn(['policyTicketsLoading', payload.policySrn], true),
    [FETCH_POLICY_TICKETS_ERROR]: (state, { payload }) =>
      state.setIn(['policyTicketsLoading', payload.policySrn], false),
    [SET_POLICY_TICKETS]: (state, { payload }) =>
      state
        .setIn(['policyTickets', payload.policySrn], fromJS(payload.tickets))
        .setIn(['policyTicketsLoading', payload.policySrn], false),
    [FETCH_POLICY_ROLLUPS]: state => state.set('policyRollupsLoading', true),
    [FETCH_POLICY_ROLLUPS_ERROR]: state =>
      state.set('policyRollupsLoading', false),
    [SET_POLICY_ROLLUPS]: (state, { payload }) =>
      state
        .set('policyRollups', fromJS(payload))
        .set('policyRollupsLoading', false),
    [FETCH_SWIMLANE_RISK]: state => state.set('swimlaneRiskLoading', true),
    [FETCH_SWIMLANE_RISK_ERROR]: state =>
      state.set('swimlaneRiskLoading', false),
    [SET_SWIMLANE_RISK]: (state, { payload }) =>
      state
        .set('swimlaneRisk', fromJS(payload))
        .set('swimlaneRiskLoading', false),
  },
  initialState
)

export default swimlaneManagerReducer
