const prefix = 'app/SwimlaneCheckupDetails/'
export const FETCH_SWIMLANE_STATS = prefix + 'FETCH_SWIMLANE_STATS'
export const SET_SWIMLANE_STATS = prefix + 'SET_SWIMLANE_STATS'
export const FETCH_SWIMLANE_STATS_ERROR = prefix + 'FETCH_SWIMLANE_STATS_ERROR'

export const FETCH_POLICY_ROLLUPS = prefix + 'FETCH_POLICY_ROLLUPS'
export const SET_POLICY_ROLLUPS = prefix + 'SET_POLICY_ROLLUPS'
export const FETCH_POLICY_ROLLUPS_ERROR = prefix + 'FETCH_POLICY_ROLLUPS_ERROR'

export const FETCH_POLICY_TICKETS = prefix + 'FETCH_POLICY_TICKETS'
export const SET_POLICY_TICKETS = prefix + 'SET_POLICY_TICKETS'
export const FETCH_POLICY_TICKETS_ERROR = prefix + 'FETCH_POLICY_TICKETS_ERROR'

export const FETCH_SWIMLANE_RISK = prefix + 'FETCH_SWIMLANE_RISK'
export const SET_SWIMLANE_RISK = prefix + 'SET_SWIMLANE_RISK'
export const FETCH_SWIMLANE_RISK_ERROR = prefix + 'FETCH_SWIMLANE_RISK_ERROR'
