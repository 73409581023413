import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import qs from 'query-string'
import { selectSwimlanes } from 'containers/SonraiData/selectors'

const selectSwimlaneCheckupDetailsDomain = state =>
  state.get('swimlaneCheckupDetails') || Map()

export const selectSwimlaneSrn = (state, props) => {
  if (props && props.location && props.location.search) {
    return qs.parse(props.location.search).swimlaneSrn
  } else {
    return null
  }
}

export const selectSwimlane = createSelector(
  [selectSwimlanes, selectSwimlaneSrn],
  (swimlanes, swimlaneSrn) =>
    swimlanes.find(swimlane => swimlane.get('srn') === swimlaneSrn) || Map()
)

export const selectPolicyTickets = createSelector(
  selectSwimlaneCheckupDetailsDomain,
  state => state.get('policyTickets') || Map()
)

export const selectPolicyTicketsLoading = createSelector(
  selectSwimlaneCheckupDetailsDomain,
  state => state.get('policyTicketsLoading')
)

export const selectPolicyRollups = createSelector(
  selectSwimlaneCheckupDetailsDomain,
  state => state.get('policyRollups') || List()
)

export const selectPolicyRollupsLoading = createSelector(
  selectSwimlaneCheckupDetailsDomain,
  state => state.get('policyRollupsLoading')
)

export const selectSwimlaneStats = createSelector(
  selectSwimlaneCheckupDetailsDomain,
  state => state.get('swimlaneStats') || Map()
)

export const selectSwimlaneRisk = createSelector(
  selectSwimlaneCheckupDetailsDomain,
  state => state.get('swimlaneRisk')
)

export const selectSwimlaneRiskLoading = createSelector(
  selectSwimlaneCheckupDetailsDomain,
  state => state.get('swimlaneRiskLoading')
)
