import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Tooltip from 'components/Tooltip'
import MarkdownDisplay from 'components/MarkdownDisplay'
import Icon from 'components/Icon'
import { themeShape } from 'containers/ThemeManager/Themeable'

const PassingPolicy = ({ policy, theme }) => {
  return (
    <div style={{ marginBottom: '0.5em' }}>
      <Tooltip
        position="top"
        styles={{ maxWidth: '80vw', width: '700px' }}
        anchor={
          <div>
            <Icon
              fa
              name="check-circle-solid"
              color={theme.success}
              style={{ marginRight: '0.5em' }}
            />
            <span style={{ fontWeight: 400 }}>{policy.get('title')}</span>
          </div>
        }
        tooltipContent={<MarkdownDisplay content={policy.get('description')} />}
      />
    </div>
  )
}

PassingPolicy.propTypes = {
  policy: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
}

export default PassingPolicy
