import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
import MarkdownDisplay from 'components/MarkdownDisplay'
import { BlockShimmer } from 'components/ShimmerLoader'
import TicketTable from 'components/TicketTable'
import TextLink from 'components/TextLink'

const FailingPolicy = ({
  policy,
  swimlaneSrn,
  ticketsCount,
  theme,
  tickets,
  ticketsLoading,
  refreshData,
  objectives,
}) => {
  return (
    <div style={{ marginBottom: '0.5em' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '30px 1fr' }}>
        <Icon
          fa
          name="exclamation-triangle-solid"
          color={theme.fail}
          style={{ marginRight: '0.5em' }}
        />{' '}
        <span style={{ fontWeight: 400 }}>{policy.get('title')}</span>
      </div>
      <div style={{ padding: '0 1.5em 1em 30px' }}>
        <MarkdownDisplay content={policy.get('description')} />
      </div>
      <div style={{ padding: '1em 1.5em 1em 30px' }}>
        {ticketsLoading ? (
          <div style={{ height: '100px' }}>
            <BlockShimmer />
          </div>
        ) : (
          <div>
            <div>
              <span style={{ fontWeight: 400, fontSize: '1.1em' }}>
                Tickets{' '}
              </span>
              <TextLink
                color="primary"
                to={{
                  pathname: '/App/Tickets',
                  state: {
                    swimlaneSrn: swimlaneSrn,
                    ticketKey: [policy.get('srn')],
                  },
                }}
              >
                View all ({ticketsCount} total)
              </TextLink>
            </div>
            <TicketTable
              tickets={tickets.toJS()}
              fitAllRows
              refreshData={refreshData}
              objectives={objectives}
            />
          </div>
        )}
      </div>
    </div>
  )
}

FailingPolicy.propTypes = {
  policy: ImmutablePropTypes.map.isRequired,
  refreshData: PropTypes.func,
  swimlaneSrn: PropTypes.string.isRequired,
  ticketsCount: PropTypes.number,
  tickets: ImmutablePropTypes.list.isRequired,
  ticketsLoading: PropTypes.bool,
  objectives: ImmutablePropTypes.list.isRequired,
  theme: themeShape,
}
export default FailingPolicy
